import { awaitWrap } from "@/page/distributor/train/front/common/unit";
import buyer_dom_manage_applyOrderCancelDetail from '@/lib/data-service/flight/buyer_dom_manage_applyOrderCancelDetail'
import buyer_dom_manage_applyOrderCancel from '@/lib/data-service/flight/buyer_dom_manage_applyOrderCancel'

export default {
    data() {
        return {
            detailLoading: false,
            detail: {},
            defaultText: "------",
            handleParams: {
                orderNo: '',
                psgNos: [],
                operationType: 1,
                operationTypeTxt: '取消编码',//操作类型
                remark: '',//备注
            },
        }
    },
    activated() {
        this.init();
    },
    methods: {
        //初始化
        init() {
            this.reset();
            this.orderNo = this.$route.query.orderNo;
            this.handleParams.orderNo = this.$route.query.orderNo;
            this.getDetail();
        },

        //重置
        reset() {
            this.detail = {};
            this.handleParams = {
                orderNo: '',
                psgNos: [],
                operationType: 1,
                operationTypeTxt: '取消编码',//操作类型
                remark: '',//备注
            };
        },

        //获取详情
        async getDetail() {
            this.detailLoading = true
            let [err, res] = await awaitWrap(buyer_dom_manage_applyOrderCancelDetail({orderNo: this.orderNo}))
            if (err) {
                this.detailLoading = false
                this.$alert(err.data.msg, '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        this.$router.go(-1);
                    }
                });
                return
            }
            this.detail = res.detailResult;
            this.detailLoading = false
        },

        //选择申请取位的乘客
        handleSelectionChange(passenger) {
            let psgNos = this.handleParams.psgNos;
            let index = psgNos.indexOf(passenger.psgNo);
            if (index > -1) {
                psgNos.splice(index, 1);
            } else {
                psgNos.push(passenger.psgNo);
            }
        },

        //取位操作
        async handleCancel() {
            if (!this.handleParams.psgNos || this.handleParams.psgNos.length === 0) {
                this.$message({
                    type: "warning",
                    message: '请选择需要申请取位的乘客'
                })
                return
            }
            this.detailLoading = true
            buyer_dom_manage_applyOrderCancel(this.handleParams).then(res => {
                this.$message({
                    type: "success",
                    message: '取位操作成功'
                })
                this.$router.go(-1);
            }).finally(() => {
                this.detailLoading = false
            })
        },

        //返回
        back() {
            this.$router.go(-1);
        }
    }
}